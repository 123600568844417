import { computed, ref } from "vue";
import axios from "axios";
import { useTokens } from "./use_tokens";

export function useCurrentUser(loggedInContact, allowLogin) {
  const { csrfToken } = useTokens();

  const localInstance = ref({});
  const username = ref("");
  const password = ref("");
  const submittingLogin = ref(false);
  const loginError = ref(null);
  const currentUser = ref(loggedInContact);
  const loginDialog = ref(false);

  const loggedIn = computed(() => allowLogin && currentUser.value !== null);

  const instance = computed(() =>
    loggedIn.value ? currentUser.value : localInstance.value
  );

  const loginAvailable = computed(
    () => allowLogin && currentUser.value === null
  );

  async function login() {
    submittingLogin.value = true;
    try {
      await axios.post(
        "/login.json",
        {
          session: {
            username: username.value,
            password: password.value,
          },
        },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      );
      await fetchUser();
      loginError.value = null;
      loginDialog.value = false;
      username.value = "";
      password.value = "";
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        loginError.value = e.response.data.error;
      } else {
        throw e;
      }
    } finally {
      submittingLogin.value = false;
    }
  }

  async function logout() {
    await axios.delete("/logout.json", {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
    currentUser.value = null;
  }

  async function fetchUser() {
    const { data } = await axios.get("/user.json");
    currentUser.value = data;
  }

  return {
    username,
    password,
    loggedIn,
    instance,
    login,
    logout,
    loginAvailable,
    submittingLogin,
    loginError,
    loginDialog,
    currentUser,
  };
}
