import { computed } from "vue";
import { useDynamicFields } from "./use_dynamic_fields";

export function useContactFields(contactFields, dynamicFields, instance) {
  const { isFieldHiddenDynamically } = useDynamicFields(
    dynamicFields,
    instance
  );

  const fieldBlockColumns = computed(() => {
    const blocksArray = Object.entries(fieldBlocks.value);
    if (blocksArray.length <= 1) {
      return [Object.fromEntries(blocksArray)];
    }
    const left = blocksArray.slice(0, Math.ceil(blocksArray.length / 2));
    const right = blocksArray.slice(
      Math.ceil(blocksArray.length / 2),
      blocksArray.length
    );
    return [Object.fromEntries(left), Object.fromEntries(right)];
  });

  const computedContactFields = computed(() =>
    contactFields.filter((fld) => !isFieldHiddenDynamically(fld))
  );

  const fieldBlocks = computed(() =>
    groupBy(computedContactFields.value, "block")
  );

  function groupBy(array, key) {
    return array.reduce(function (grouped, obj) {
      (grouped[obj[key]] = grouped[obj[key]] || []).push(obj);
      return grouped;
    }, {});
  }

  return { fieldBlockColumns, computedContactFields, fieldBlocks };
}
