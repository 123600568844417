<template>
  <q-checkbox
    v-model="value"
    :disable="!edit"
    true-value="1"
    false-value="0"
  >
    <span
      v-html="label"
      @click="onLabelClick"
    ></span>
  </q-checkbox>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    modelValue: { default: "0" },
    edit: Boolean,
    label: String,
  },

  emits: ["update:modelValue"],

  setup(props, ctx) {
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        ctx.emit("update:modelValue", newValue);
      },
    });

    function onLabelClick(event) {
      if (event.target.tagName === "A") {
        event.stopPropagation();
      }
    }

    return {
      value,
      onLabelClick,
    };
  },
};
</script>
