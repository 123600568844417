export function useCurrency(currency) {
  function formatCurrency(value) {
    const locale = currency === "SEK" ? "sv" : "fi";
    const options = {
      style: "currency",
      currency,
    };

    if (Number.isInteger(value)) {
      options.minimumFractionDigits = 0;
      options.maximumFractionDigits = 0;
    }

    return new Intl.NumberFormat(locale, options).format(value);
  }

  return { formatCurrency };
}
