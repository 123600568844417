import { computed } from "vue";

export function useDynamicFields(dynamicFields, instance) {
  const activeDynamicConditions = computed(() =>
    dynamicFields.filter((dyn) =>
      isConditionActive(dyn, instance.value[dyn.field])
    )
  );

  function isFieldHiddenDynamically(fld) {
    return activeDynamicConditions.value.some((dyn) => {
      return dyn.fields[fld.name]?.show === false;
    });
  }

  function isConditionActive(dyn, val) {
    switch (dyn.operator) {
      case "eq":
        return String(val) === String(dyn.value);
      case "neq":
        return String(val) !== String(dyn.value);
      case "in":
        return dyn.value.split(",").includes(String(val));
      // TODO: other operators
      default:
        return false;
    }
  }

  return { isFieldHiddenDynamically };
}
